precision mediump float;

uniform sampler2D matcap;
uniform float uProgress;
uniform float uTime;
uniform vec4 resolution;
uniform float u_camDistance;
uniform float u_camX;
uniform float u_camY;
uniform float u_SphereX;
uniform float u_SphereY;
uniform float u_SphereZ;
uniform float u_SphereRadius;
uniform float u_Smoothness;
uniform float uBlend;

varying vec2 vUv;
varying vec2 vPosition;
float PI = 3.141592653589793238;


mat4 rotationMatrix(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    
    return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                0.0,                                0.0,                                0.0,                                1.0);
}

vec2 getMatcap(vec3 eye, vec3 normal) {
  vec3 reflected = reflect(eye, normal);
  float m = 2.8284271247461903 * sqrt( reflected.z+1.0 );
  return reflected.xy / m + 0.5;
}

vec3 rotate(vec3 v, vec3 axis, float angle) {
	mat4 m = rotationMatrix(axis, angle);
	return (m * vec4(v, 1.0)).xyz;
}
float smin( float a, float b, float k )
{
    float h = max( k-abs(a-b), 0.0 )/k;
    return min( a, b ) - h*h*k*0.25;
}
float smax( float a, float b, float k )
{
    float h = max( k-abs(a-b), 0.0 )/k;
    return max( -b, a ) - h*h*k*0.25;
}


float sdSphere( vec3 p, float radius )
{
  return length(p)-radius;
}
float sdBox( vec3 p, vec3 b )
{
  vec3 q = abs(p) - b;
  return length(max(q,0.0)) + min(max(q.x,max(q.y,q.z)),0.0);
}



// create the scene (on créer un objet imaginaire)
float sdf(vec3 p){
  vec3 p1 = rotate(p, vec3(1.), uTime/5. );
  float box = sdBox(p1, vec3(0.5, 0.25, 0.2));
  // float sphere = sdSphere(vec3(p.x-u_SphereX, p.y-u_SphereY, p.z-u_SphereZ), u_SphereRadius);
  // float sphere = sdSphere(vec3(cos(-p.x+uTime), p.y, p.z-u_SphereZ), u_SphereRadius);
float sphereX = cos(0.5 * p.x - uTime * 0.75);

float sphereY = 0.5*p.y;
float sphereZ = 0.5*p.z - u_SphereZ;
float sphere = sdSphere(vec3(sphereX, sphereY, sphereZ), u_SphereRadius*0.5);


  if(uBlend == 0.0){
  return smin(box, sphere,u_Smoothness*0.5);

  } else {
  return smax(box, sphere,u_Smoothness*0.5);

  }



  //?othe transfo
  //intersection
  // return max(sphere, box);  
  //soustraction
  // return max(-sphere, box);
}
vec3 calcNormal( in vec3 p ) // for function f(p)
{
    const float eps = 0.0001; // or some other value
    const vec2 h = vec2(eps,0);
    return normalize( vec3(sdf(p+h.xyy) - sdf(p-h.xyy),
                           sdf(p+h.yxy) - sdf(p-h.yxy),
                           sdf(p+h.yyx) - sdf(p-h.yyx) ) );
}


void main() {

// normalize les coordonnées de texture entre 0 et 1
  vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
  vec3 camPos = vec3(u_camX, u_camY, u_camDistance);

  
  vec3 ray = normalize(vec3((vUv - vec2(0.5))*resolution.zw, -1));
  vec3 rayPos = camPos;
  float t = 0.;
  float tMax = 5.;

// code executé pour chaque rayon
  for(int i=0; i< 256; i++){
    vec3 pos = camPos + t*ray;
    float h = sdf(pos);
    t+=h;
    if(h < 0.0001 || t > tMax) break;
  }
      vec3 color = vec3(0.8,0.737,0.686);

// code executé au contact de quelque chose 
    if(t< tMax){
    vec3 pos = camPos + t*ray;

      color = vec3(1.);
      vec3 normal = calcNormal(pos);
      color = normal;
      float diff = dot(vec3(1.), normal);
      vec2 matcapUV = getMatcap(ray, normal);
      // color = vec3(diff);
      // color = vec3(matcapUV, 1.);
      color = vec3(diff);
      color = texture2D(matcap, matcapUV).rgb;


    }



  // Assigner la couleur au fragment
  gl_FragColor = vec4(color,  1.);
  // gl_FragColor = vec4(color, 1.);
}
