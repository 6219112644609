import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import testVertexShader from './shaders/test/vertex.glsl'
import testFragmentShader from './shaders/test/fragment.glsl'
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { GammaCorrectionShader } from 'three/addons/shaders/GammaCorrectionShader.js';
import { SMAAPass } from 'three/addons/postprocessing/SMAAPass.js';
import { SSAARenderPass } from 'three/addons/postprocessing/SSAARenderPass.js';




/**
 * Base
 */
// Debug
// const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color('#e0d6cf')

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('./textures/matcaps/03.png')


/**
 * Light
 */


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    //Update the effect Composer
    effectComposer.setSize(sizes.width, sizes.height)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Mesh
 */


//Plane
const planeGeometry = new THREE.PlaneGeometry(1, 1)
const planeMaterial = new THREE.ShaderMaterial({
    side: THREE.DoubleSide,
    vertexShader: testVertexShader,
    fragmentShader: testFragmentShader,


})


// aspect ratio de l'image
let a1 = sizes.width / sizes.height
let a2 = 1


planeMaterial.uniforms = {
    uTime: { value: 0 },
    resolution: { value: new THREE.Vector4(sizes.width, sizes.height, a1, a2) },
    u_camDistance: { value: 3 },
    u_camY: { value: 0 },
    u_camX: { value: 0 },
    u_SphereX: { value: 0 },
    u_SphereY: { value: 0 },
    u_SphereZ: { value: 0 },
    u_SphereRadius: { value: 0.2 },
    u_Smoothness: { value: 0.8 },
    matcap: { value: matcapTexture },
    uBlend: { value: 0 }

}
// gui.add(planeMaterial.uniforms.u_camDistance, 'value', 1, 10, 0.001).name('Distance de la camera')
// gui.add(planeMaterial.uniforms.u_camX, 'value', -2, 2, 0.001).name('camera X')
// gui.add(planeMaterial.uniforms.u_camY, 'value', -2, 2, 0.001).name('camera Y')
// gui.add(planeMaterial.uniforms.u_SphereX, 'value', -2, 2, 0.001).name('sphereX')
// gui.add(planeMaterial.uniforms.u_SphereY, 'value', -2, 2, 0.001).name('sphereY')
// gui.add(planeMaterial.uniforms.u_SphereZ, 'value', -2, 2, 0.001).name('sphereZ')
// gui.add(planeMaterial.uniforms.u_SphereRadius, 'value', 0.1, 0.6, 0.001).name('sphere Radius')
// gui.add(planeMaterial.uniforms.u_Smoothness, 'value', 0.001, 1, 0.001).name('Smoothness')
// gui.add(planeMaterial.uniforms.uBlend, 'value', 0, 1, 1).name('Blending')

const plane = new THREE.Mesh(planeGeometry, planeMaterial)
scene.add(plane)


/**
 * Camera
 */
// Base camera

// ortho camera
const camera = new THREE.OrthographicCamera(1 / -2, 1 / 2, 1 / 2, 1 / - 2, -1000, 1000);
camera.position.set(0, 0, 1)

scene.add(camera);


// pers camera
// const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
// camera.position.set(0, 0, 1)
// scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,

})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(1)


//post processing section
const renderTarget = new THREE.WebGLRenderTarget(
    800,
    600,
    {
        samples: renderer.getPixelRatio() === 1 ? 2 : 0,
    }

)


const effectComposer = new EffectComposer(renderer, renderTarget)

effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
effectComposer.setSize(sizes.width, sizes.height)

// renderPass du de trouver de la doc la dessus, lui paser la scene et la cam, c'est l'effet qui permet de remplacer le renderer pas l'effect composer
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)

//todo pour corriger l'aspect sombre de beaucoup d'effets, on ajoute ce dernier effet à la toute fin
const gammaCorrection = new ShaderPass(GammaCorrectionShader)
gammaCorrection.enabled = false
effectComposer.addPass(gammaCorrection)

// const smaaPass = new SMAAPass()
// effectComposer.addPass(smaaPass)

/**
 * Animate
 */
const clock = new THREE.Clock()



// Mesh
const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    planeMaterial.uniforms.uTime.value = elapsedTime
    camera.position.left = (Math.sin(elapsedTime * 0.5) + 1.5) / 3






    // controls.update()
    // renderer.render(scene, camera)
    effectComposer.render()
    window.requestAnimationFrame(tick)
}

tick()